import React from "react"

import Layout from "../../components/litjen-layout"
import SEO from "../../components/litjen-seo"

const LitjenLuke = () => (
  <Layout>
    <SEO title="Luke 16" />
    <div>
      <h2>En Litjen adventskalender</h2>
      <h1>Luke 16</h1>
      <p>Mamma, jeg har det så fint med deg og pappa!</p>
      <p>Det er derfor jeg ligger rundt omkring og bare smiler, mens jeg betatt ser på dere. Innimellom sender jeg et lite kjærlighetsrop i form av en lyd som jeg bare finner opp på stedet.</p>
      <p>Gleder meg til hver dag med dere, slik jeg hører dere gleder dere til hver dag med meg (ryktet stammer fra Fargekladden).</p>
      <p>Håper det smaker med noen energibars!? Jeg krever jo litt energi av deg, snille mamma &lt;3</p>
      <p>Hilsen Litjen</p>
    </div>
  </Layout>
)

export default LitjenLuke
